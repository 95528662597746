<!-- <ion-header>
        <ion-toolbar>
            <ion-buttons slot="start">
                <ion-button appDebounceClick (throttleClick)="dismissModal()" class="back-btn">
                    <ion-icon name="arrow-back"></ion-icon>
                    <p>{{"global.previous" | translate}}</p>
                </ion-button>
            </ion-buttons>
            <ion-title>
                {{mainItem.name}}
            </ion-title>
            <ion-text class="header-in-away" *ngIf="orderService.mode =='takeaway'" slot="end"> {{"pages.splash.takeout"|translate}}</ion-text>
            <ion-text class="header-in-away" *ngIf="orderService.mode =='dinein'" slot="end">{{"pages.splash.dine-in"|translate}}</ion-text>
        </ion-toolbar>
</ion-header> -->
<div class="inPage-header-box" slot="fixed">
    <div class="back-btn-box">
        <ion-button fill="clear" appThrottleClick (throttleClick)="dismissModal()" class="back-btn AQA-back-btn">
            <ion-icon name="close"></ion-icon>
        </ion-button>
    </div>

</div>

<ion-content class="set-selection">


    <div class="sub-header">
        <div class="bg-img" *ngIf="mainItem.getImage('default', 375, true) as itemImage"
             [ngStyle]="{'background-image': 'url(' + itemImage + ')', 'height':orderService.screenWidthUnit * 50 + 'px'}">
        </div>
        <div class="sub-header-inner" padding>
            <div class="item-info-box">
                <div class="left-col">
                    <span class="title-text">{{mainItem.name}}</span>
                    <p *ngIf="mainItem.desc" class="desc-text AQA-itemDesc">{{mainItem.desc}}</p>
                </div>
                <div class="right-col">
                    <span class="price-text itemPrice-text"
                          *ngIf="settings && !settings.hidePrice">{{orderManager?.store?.currencySign}}{{mainItem.price}}</span>
                    <!-- <div class="hint-box-wrapper" *ngIf="isAutoPickupHasmodifier">
                                <span class="hint-box-inner">
                                    <button class="modifier-btn" [ngClass]="{'incomplete': !isAutoPickupModifierCompleted}" ion-button color="warning" (click)="modifierClicked();$event.stopPropagation();">
                                        <p>{{"pages.cart.special-request" | translate}}</p>
                                    </button>
                                    <div *ngIf="!isAutoPickupModifierCompleted" class="hint-box ig-hint pop">
                                        <span>{{"pages.set-select.please-select" | translate}}</span>
                                    </div>
                                </span>
                            </div> -->
                </div>
            </div>
            <ng-container *ngIf="isAutoPickup ">
                <div *ngFor="let autoPickGroup of  autoPickupGroups ; trackBy:checkDifference"
                     class="default-item-box">
                    <div class="default-icon">{{"pages.set-select.serve-with" | translate}}</div>
                    <div class="default-item-wrapper">
                        <div class="default-item-inner">
                            <div class="default-item">
                                <span *ngFor="let item of autoPickGroup?.items ;trackBy:checkDifference">
                                    {{item?.name}}
                                    <!-- <span>{{getSelectedItemNamesModifier(index+1)}}</span>
                                                    <ng-container *ngIf="groups[index].min > 1 && groups[index].repeat">x{{groups[index].min}}
                                                    </ng-container> -->
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="specialRequest-box" *ngIf="isAutoPickupHasModifier" (click)="modifierClicked();$event.stopPropagation();"
                 [ngClass]="{'incomplete AQA-mod-not-completed': !isAutoPickupModifierCompleted, 'AQA-mod-completed': isAutoPickupModifierCompleted}">
                <div class="left-col">
                    <p>{{"pages.cart.special-request" | translate}}</p>
                </div>
                <div class="hint-box-wrapper">
                    <span class="hint-box-inner">
                        <ion-button size="small" class="modifier-btn" fill="clear"
                                    [ngClass]="{'incomplete AQA-mod-not-completed': !isAutoPickupModifierCompleted, 'AQA-mod-completed': isAutoPickupModifierCompleted}" color="success">
                            <p>{{"pages.cart.edit" | translate}}</p>
                        </ion-button>
                        <!-- <div *ngIf="!isAutoPickupModifierCompleted" class="hint-box ig-hint pop">
                                                    <span>{{"pages.set-select.please-select" | translate}}</span>
                                                </div> -->
                    </span>
                </div>
            </div>
        </div>
    </div>
    <ion-list *ngIf="nonAutoPickupGroups" class="set-list-counter">
        <div tappable *ngFor="let group of nonAutoPickupGroups ;let i = index;trackBy:checkDifference"
             class="item-cat-wrapper"  [attr.data-ig-min]="group.min" [attr.data-ig-max]="group.max">
            <div (click)="toggleGroupExpansion(group.id)" class="item-cat-box"
                 [ngClass]="{'expanded': isExpand[group.id]}">
                <div class="item-cat-info-box">
                    <div class="region-title">
                        <p>{{group.name}}</p>
                        <span class="request-tag-box"
                              [ngClass]="{'completed AQA-mod-completed': isGroupCompleted(group),'missing AQA-mod-not-completed': !isGroupCompleted(group) && missing }">
                            <ion-icon name="md-checkmark" color="medium"></ion-icon>
                            <ng-container *ngIf="group?.optional">
                                {{'global.please-select-max' | translate}}</ng-container>
                            <ng-container *ngIf="!group?.optional">
                                {{'global.please-select' | translate}}</ng-container>
                            <span class="request-num-text">
                                <ng-container *ngIf="group.min != group.max"><strong>{{group.min}}</strong> -
                                </ng-container>
                                <strong>{{group.max}}</strong>
                            </span>{{'global.items' | translate}}
                        </span>
                    </div>


                    <div slot="end" class="right-pan" *ngIf="!group?.optional && group.min != 0">
                        <ion-text color="medium">*{{"pages.modifier-item-select.require" | translate}}</ion-text>
                        <!-- <ng-container
                                *ngIf="isCurrentGroupHasModifier(group) && !(builder?.noNeedSelected(groups?.indexOf(group) + 1, false))">
                            <ion-button fill="solid" color="success" size="small"
                                        (click)="showModifierPopup(group);$event.stopPropagation();">
                                <p>{{"pages.modifier-item-select.modify" | translate}}</p>
                            </ion-button>
                        </ng-container> -->
                    </div>
                </div>

                <div class="selected-item-chip-box">
                    <span *ngFor="let name of getSelectedItemNamesArr(group)"
                          class="selected-item-chip animated zoomIn AQA-selected-item">
                        {{name}}
                    </span>
                </div>
                <!-- <div *ngIf="isCurrentGroupHasModifier(group)">
                    <ion-button class="modifier-icn" [ngClass]="{'incomplete': !isRequiredModifierSelectByGroup(group)}"
                        fill="clear" (click)="showModifierPopup(group);$event.stopPropagation();">
                        <p>{{"pages.cart.special-request" | translate}}</p>
                    </ion-button>
                </div> -->

                <div>
                    <!-- <ion-icon name="{{isExpand[group.id] ? 'arrow-up' : 'arrow-down'}}"></ion-icon> -->
                </div>
            </div>
            <!-- 展示每一项的配置要求 -->
            <div *ngIf=" itemGroupMap[group.id]" class="set-select-item">
                <div class="parent-list-wrapper">

                    <!-- noNeed option -->
                    <ion-item *ngIf="group?.optional" (click)="clickedNoNeed(group, i+1)" class="AQA-noNeed-button">

                        <ion-row item-left class="select-panel">
                            <ion-col>
                                <p>{{"pages.item-select.no-need" | translate}}</p>
                            </ion-col>
                        </ion-row>

                        <div class="left-btn-box">
                            <ion-icon class="radio-icon"
                                      name="{{isNoNeedSelected(group) ? 'checkmark-circle' : 'radio-button-off'}}"
                                      color="{{isNoNeedSelected(group) ? 'success' : 'medium'}}" size="large">
                            </ion-icon>
                        </div>

                    </ion-item>

                    <ion-item *ngFor="let item of itemGroupMap[group.id];trackBy:checkDifference" (click)="addClicked(item,group);"
                              [ngClass]="{'selected-item' : (getGroupMode(group) == 'single' && has(item, group))}"
                              class="item-list-box">

                        <!-- sold out box -->
                        <div *ngIf="isSoldout(item)" class="sold-out-box AQA-soldOut">
                            <p>{{"pages.item-grid.soldout" | translate}}</p>
                        </div>
                        <div class="item-wrapper">
                            <div class="item-inner-wrapper">
                                <!-- sub-item-info -->
                                <ion-row class="select-panel">
                                    <ion-col class="item-info-col">
                                        <p>{{item.name}}</p>
                                        <p class="itemPrice-text">+{{orderManager?.store?.currencySign}}{{item.price}}</p>
                                    </ion-col>
                                </ion-row>

                                <!-- alterQty -->
                                <div *ngIf="getGroupMode(group) == 'quantity'" slot="end" class="minus-qty-btn"
                                     [ngClass]="{'btn-added': has(item, group)}">
                                    <ion-button (click)="minusClicked(item, group);$event.stopPropagation();"
                                                class="qty-btn" color="medium" fill="clear">
                                        <ion-icon name="remove-circle-outline" size="large"></ion-icon>
                                    </ion-button>
                                    <ng-container *ngIf="group.max > 1">
                                        <p [ngClass]="{'bold-text': has(item, group)}">
                                            x{{getQuantity(item, group) || 0}}</p>
                                    </ng-container>
                                </div>
                                <div slot="end" class="left-btn-box">
                                    <ion-icon *ngIf="getGroupMode(group) == 'single'"
                                              name="{{has(item, group) ? 'checkmark-circle' : 'radio-button-off'}}"
                                              [class.checked]="has(item, group)" [class.unchecked]="!has(item, group)"
                                              color="success" size="large">
                                    </ion-icon>

                                    <div *ngIf="getGroupMode(group) == 'quantity'">
                                        <ion-button class="qty-btn add-qty-btn" color="medium" fill="clear"
                                                    [ngClass]="{'btn-added': has(item, group) ? 'is-completed':isCompleted() }">
                                            <ion-icon class="add-icon" name="add-circle-outline"
                                                      color="{{ has(item, group)?'success':'medium'}}" size="large">
                                            </ion-icon>
                                        </ion-button>
                                    </div>
                                </div>
                            </div>
                            <div (click)="showModifierPopup(group);$event.stopPropagation();"
                                 *ngIf="has(item, group) && isCurrentGroupHasModifier(group) && !(builder?.noNeedSelected(groups?.indexOf(group) + 1, false))"
                                 class="select-lower AQA-HasModifier">
                                <div class="modify-left">
                                    <h2>{{"pages.cart.special-request" | translate}}</h2>
                                    <p>{{getSelectedItemNamesModifier(group, item)}}</p>
                                </div>
                                <div class="modify-edit">
                                    <span>{{"pages.cart.edit" | translate}}</span>
                                </div>
                            </div>
                        </div>


                    </ion-item>

                    <ion-item *ngFor="let title of titles[group.id];let i = index;trackBy:checkDifference"
                              (click)="titleGroupClicked(title,i,group.id)" class="item-list-box parent-list">
                        <div style="width:100%;">
                            <div class="parent-list-box">
                                <ion-row class="select-panel parent-panel drink-box">
                                    <ion-col>
                                        <p class="AQA-subGroup-title"
                                                *ngIf="title == 'hot-drink' || title == 'cold-drink'|| title == 'special-drink' || title == 'soup'">
                                            {{'drinks.' + title | translate}}</p>
                                        <p class="AQA-subGroup-title"
                                                *ngIf="!(title == 'hot-drink' || title == 'cold-drink'|| title == 'special-drink' || title == 'soup')">
                                            {{title}}</p>
                                    </ion-col>
                                </ion-row>
                                <div class="arrow-icn-box">
                                    <ion-icon class="arrow-icn" size="large" color="medium"
                                              name="{{isTitleGroupExpand[group.id][title] ? 'ios-arrow-dropup' : 'ios-arrow-dropdown'}}">
                                    </ion-icon>
                                </div>
                            </div>
                            <div class="child-list-wrapper" id="child-{{group.id}}-{{i}}"
                                 [ngClass]="{'isExpand' : isTitleGroupExpand[title] }">
                                <ion-item *ngFor="let sameTitleItem of sameTitleItems[group.id][title];trackBy:checkDifference"
                                          (click)="$event.stopPropagation();addClicked(sameTitleItem,group)"
                                          [ngClass]="{'selected-item' : (getGroupMode(group) == 'single' && has(sameTitleItem,group))}"
                                          class="item-list-box child-list">
                                    <div *ngIf="isSoldout(sameTitleItem)" class="sold-out-box AQA-soldOut">
                                        <p>{{"pages.item-grid.soldout" | translate}}</p>
                                    </div>
                                    <div class="item-wrapper">
                                        <div class="item-inner-wrapper">

                                            <!-- sub-item-info -->
                                            <ion-row class="select-panel">
                                                <ion-col class="item-info-col">
                                                    <p>{{sameTitleItem.name}}</p>
                                                    <p class="itemPrice-text">+{{orderManager?.store?.currencySign}}{{sameTitleItem.price}}</p>
                                                </ion-col>
                                            </ion-row>

                                            <!-- alterQty -->
                                            <div *ngIf="getGroupMode(group) == 'quantity'" slot="end"
                                                class="minus-qty-btn"
                                         [ngClass]="{'btn-added': has(sameTitleItem,group)}">
                                        <ion-button
                                                (click)="minusClicked(sameTitleItem, group);$event.stopPropagation();"
                                                class="qty-btn" color="light-tint" fill="clear">
                                            <ion-icon name="remove-circle-outline" size="large" color="success">
                                            </ion-icon>
                                        </ion-button>
                                        <ng-container *ngIf="group.max > 1">
                                            <p [ngClass]="{'bold-text': has(sameTitleItem,group)}">
                                                {{getQuantity(sameTitleItem, group) || 0}}</p>
                                        </ng-container>
                                    </div>


                                    <div slot="end" class="left-btn-box">
                                        <ion-icon *ngIf="getGroupMode(group) == 'single'"
                                                  name="{{has(sameTitleItem,group) ? 'checkmark-circle' : 'radio-button-off'}}"
                                                  [class.checked]="has(sameTitleItem,group)"
                                                  [class.unchecked]="!has(sameTitleItem,group)" color="success"
                                                  size="large">
                                                </ion-icon>

                                        <div *ngIf="getGroupMode(group) == 'quantity'">
                                            <ion-button class="qty-btn add-qty-btn" color="medium" fill="clear"
                                                        [ngClass]="{'btn-added': has(sameTitleItem,group) ? 'is-completed':isCompleted() }">
                                                <ion-icon class="add-icon" name="add-circle-outline"
                                                          color="{{ has(sameTitleItem,group)?'success':'medium'}}"
                                                          size="large">
                                                </ion-icon>
                                            </ion-button>
                                        </div>
                                    </div>
                                        </div>
                                        <div (click)="showModifierPopup(group);$event.stopPropagation()"
                                            *ngIf="has(sameTitleItem, group) && isCurrentGroupHasModifier(group) && !(builder?.noNeedSelected(groups?.indexOf(group) + 1, false))"
                                            class="select-lower">
                                            <div class="modify-left">
                                                <h2>{{"pages.cart.special-request" | translate}}</h2>
                                                <p>{{getSelectedItemNamesModifier(group, sameTitleItem)}}</p>
                                            </div>
                                            <div class="modify-edit">
                                                <span>{{"pages.cart.edit" | translate}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </ion-item>
                            </div>
                        </div>
                    </ion-item>
                </div>

            </div>
        </div>

    </ion-list>


</ion-content>

<ion-footer>
    <div class="sub-footer">
        <!--<div class="selected-item-box"
                [ngClass]="{'hs-item animated fadeInUps': builder.items.length > 1 && openSelectedBox}"
                class="selected-item-box">
                <div *ngFor="let item of builder.items| slice: 1" class="selected-item">{{item.name}} x{{item.quantity}}
                </div>
            </div>-->
        <ion-toolbar no-padding class="btn-box" color="contrast">
            <!-- disable -->
            <!-- <div class="si-btn-box" style="width: 20vw;float: left;">
                <ion-button expand="block" (click)="openSelected()" [disabled]="builder.itemGroupMap.length == 1"
                            color="danger">
                    <span class="seleted-text">
                        <ion-icon [ngClass]="{revert: openSelectedBox}" name="ios-arrow-up"></ion-icon>
                        <p>{{"pages.set-select.picked" | translate}}</p>
                    </span>

                </ion-button>
            </div> -->
            <div class="add-btn-box">
                <ion-button slot="start" expand="block" (click)="addToCartClicked()"
                            [ngClass]="{'completed': isCompleted()}" class="addCart-btn">
                    <div class="add-btn-text animated fadeInUps" *ngIf="isCompleted()">
                        <ion-icon name="add"></ion-icon>
                        <p>{{ (edit ? "pages.set-select.edit-order" : "pages.set-select.confirm-order") | translate }}
                        </p>
                    </div>
                    <div class="add-btn-text" *ngIf="!isCompleted()">
                        <p>{{"pages.set-select.complete-first" | translate}}</p>
                    </div>
                    <p class="price-text" *ngIf="settings && !settings.hidePrice">
                        {{orderManager?.store?.currencySign}}{{total}}</p>
                </ion-button>
            </div>
        </ion-toolbar>
    </div>
</ion-footer>
